@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Orbitron&family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Notable&display=swap');

body {
  background-color: var(--color-3);
}

html {
  scroll-behavior: smooth;
}

:root {
  --color-1: #ff296d;
  --color-2: #d1f7ff;
  --color-3: #01012a;
  --color-4: #005679;
  --color-5: #05d8e8;
  --extra-1: "Righteous";
  --extra-2: "Notable";
  --home-font: "Orbitron";
  --title-font: "Bubblegum Sans";
  --content-font: "Space Mono";
}

#navbar {
  color: var(--color-1);
  width: 80%;
  margin: 0% 10%;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

#navbar>*>* {
  color: var(--color-1);
  font-family: var(--content-font);
}

#spacer {
  width: 70%;
}

.link {
  text-decoration: none;
  padding: 15px;
}

#navbar>div {
  font-size: 20px;
}

#socials_icons {
  display: flex;
  flex-direction: row;
}

#socials_icons>* {
  padding: 10px;
}


#home {
  /* border: 1px solid yellow; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
}

#title-home {
  position: absolute;
  top: 25%;
  text-align: center;
  font-size: 10vw;

  background: -webkit-linear-gradient(var(--color-1), var(--color-3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--home-font), sans-serif;
}


#image {
  /* border: 5px solid white;  */
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 10vh;
  width: 50%;
  aspect-ratio: 1;
  max-width: 500px;
  text-align: center;
  z-index: 2;
  margin-bottom: 45vh;
}

#home-bg {
  width: 100%;
  height: auto;
  filter: hue-rotate(260deg);
}

#scroll-icon {
  border-radius: 5px solid white;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

#scroll-title {
  color: var(--color-5);
  font-family: var(--content-font);
  letter-spacing: 2px;
  z-index: 2;
}

.fa-down-long {
  padding-left: 10px;
  color: var(--color-5);
}

#skills-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


@media screen and (min-width: 1000px) {
  #skills-cards {
    flex-direction: column;
  }
}

#skills>h1,
#works>h1,
#contact-title {
  text-align: center;
  color: var(--color-1);
  font-family: var(--home-font);
  font-size: 70px;
  background: -webkit-linear-gradient(var(--color-1), var(--color-3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.card {
  width: 25 vh;
  min-width: 300px;
  aspect-ratio: 1 / 1.5;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-1);
  font-family: "Righteous";
  font-size: 20px;
}

#home-slogan {
  font-family: var(--content-font);
  letter-spacing: 5px;
  background: -webkit-linear-gradient(var(--color-1), var(--color-3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flicker 1s ease-in-out infinite alternate;
}

.fa-code,
.fa-photo-film,
.fa-meteor {
  font-size: 100px;
}

#works {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tab-button {
  margin: 10px;
}


.tab-button {
  background-color: #01012a;
  border: none;
  color: #ff296d;
  font-family: var(--extra-1);
  font-size: 20px;
  margin: 0;
  padding: 0 10px;

}

.active {
  background-color: var(--color-1);
  color: var(--color-3);
  border-radius: 15px;
}

#contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  width: 100vw;
}

label {
  display: block;
  color: var(--color-1);
  font-family: var(--extra-2);
  margin: 5px 0;
}

#contact-img {
  width: 55%;
  max-width: 700px;
}

#contact>button,
input {
  display: block;
}

form>input,
form>textarea {
  background-color: #01012a;
  border: 1px solid var(--color-4);
  border-width: 0 0 2px 0;
  color: var(--color-1);
  font-family: var(--extra-1);
  font-size: 15px;
  width: 100%;
  resize: none;
}

#contact-button {
  color: #01012a;
  border: none;
  background-color: #ff296d;
  font-family: var(--extra-1);
  font-size: 20px;
  margin: 0;
  padding: 0 10px;
  border-radius: 40px;
  margin: 10px;
}

.contact-icons {
  font-size: 30px;
  margin: 0 10px;
  color: var(--color-1);
}

#contact-socials>*>span {
  text-decoration: none;
  display: inline;
  font-family: var(--extra-2);
  font-size: 1em;
  color: var(--color-1);
}

#contact-socials {
  padding-bottom: 1em;
}

#cyberpunk-track {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

#cyberpunk-track>img {
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  #title-home {
    font-size: 6em;
  }

}

@media only screen and (max-width: 1000px) {
  #skills-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card{
    aspect-ratio: 3 / 2;
    width : 80vw;
  }
}